<template>
  <div class="chart-contanier">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="医嘱执行率排名">
        <div v-if="currentTab == 1">
          <div class="filter-items">
            <a-row class="flex">
              <a-col v-if="!isHospital">
                <a-select placeholder="请选择医院" style="width: 180px" v-model="hospitalId" :allow-clear="false"
                  @select="selectedHospital">
                  <a-select-option v-for="(item, index) in hospitalList" :key="index" :value="item.hospitalId">
                    {{ item.name }}</a-select-option>
                </a-select>
              </a-col>
              <a-col style="flex:1; text-align: end;">
                <a-button type="primary" @click="derive">下载数据</a-button>
              </a-col>
            </a-row>
          </div>
          <div class="table-plate">
            <ant-tabel :data="rankList" :columns="columns" :loading="loading" :pagination="pagination"
              recordKey="hospitalExecutionRankingId"></ant-tabel>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="医嘱执行率" force-render>
        <div v-if="currentTab == 2">
          <searchPlate @search="getData"></searchPlate>
          <div class="table-plate">
            <lineChart id="bloodSugar" :echartData="implementationRate"></lineChart>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="采纳率">
        <div v-if="currentTab == 3">
          <searchPlate @search="getData"></searchPlate>
          <div class="table-plate">
            <lineUnitChart id="TirAndCv" :echartData="adoptionRate"></lineUnitChart>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>

import moment from "moment";
import { message } from "ant-design-vue";
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import excelUtil from "../../utils/dealwithExcelUtil";

import searchPlate from "./search.vue";
import lineChart from "./charts/line.vue";
import lineUnitChart from "./charts/lineUnit.vue";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";

const columns = [
  { title: "护士姓名", dataIndex: "realName", key: "realName" },
  { title: "医嘱执行率", dataIndex: "rate", key: "rate" },
  { title: "提前1h操作次数", dataIndex: "adv", key: "adv" },
  { title: "超时1h操作次数", dataIndex: "lag", key: "lag" },
  { title: "总操作次数", dataIndex: "total", key: "total" },
];

const rankList = [];
export default {
  components: { lineChart, lineUnitChart, searchPlate, AntTabel },
  data() {
    return {
      currentTab: 1,
      mainDiagnosisList: [],
      // 执行率 
      implementationRate: {
        title: '',
        yAxisUnit: '',
        xAxisData: [],
        series: [{
          name: "执行率",
          color: '#6093FF',
          yAxisData: [],
        }
        ],
        noData: true
      },
      // 采纳率
      adoptionRate: {
        title: '',
        yAxisUnit: '',
        xAxisData: [],
        series: [{
          name: "记录采纳率",
          color: '#06C270',
          yAxisData: [],
        },
        {
          name: "校正采纳率",
          color: '#FF8800',
          yAxisData: [],
        }
        ],
        noData: true
      },
      hospitalList: storage.getItem("hospitalList"),
      patientList: [],
      patientKeyword: '',//患者列表关键字查询
      // 个人查询条件
      isHospital: false,
      hospitalId: undefined,
      rankList,
      columns,
      loading: false,
      pagination: {
        hidePage: true // 隐藏分页
      },
    };
  },
  mounted() {
    let users = storage.getItem("users");
    if (users && users.hospitalId) {
      this.isHospital = true;
      this.hospitalId = users.hospitalId;
    } else {
      this.isHospital = false;
    }

    this.getAccount();
  },

  methods: {
    selectedHospital() {
      this.loading = true;
      this.getAccount();
    },
    getAccount() {
      if (!this.hospitalId) {
        return;
      }
      let params = {
        hospitalId: this.hospitalId
      }
      api.getHospitalExecutionRanking(params).then((res) => {
        this.loading = false;
        res.data.forEach(item => {
          item.rate = item.rate == 100000 ? 'NAN' : item.rate;
        });
        this.rankList = res.data;
      });
    },
    getData(params) {
      this.clearData();

      this.getHospitalExecutionRate(params);
      this.getHospitalAdoptRate(params);
    },
    // 采纳率
    getHospitalAdoptRate(params) {
      let { hospitalId } = params.data;
      if (!hospitalId) {
        return;
      }
      let paramsData = {
        startTime: params.selectedRange[0],
        endTime: params.selectedRange[1],
        hospitalId,
      }
      api
        .getHospitalAdoptRate(paramsData)
        .then(({ data }) => {
          if (data.length == 0) {
            this.adoptionRate.noData = true;
          }
          data.forEach(item => {
            this.adoptionRate.xAxisData.push(moment(item.day).format('YYYY-MM-DD'));
            if (item.adoptRate == undefined || item.adoptRate == null || item.adoptRate == '') {
              this.adoptionRate.series[0].yAxisData.push(null)
            } else {
              this.adoptionRate.series[0].yAxisData.push((Number(item.adoptRate) * 100).toFixed(2));
            }
            if (item.reviseRate == undefined || item.reviseRate == null || item.reviseRate == '') {
              this.adoptionRate.series[1].yAxisData.push(null)
            } else {
              this.adoptionRate.series[1].yAxisData.push((Number(item.reviseRate) * 100).toFixed(2));
            }
          });
        });
    },
    // 获取患者血糖
    getHospitalExecutionRate(params) {
      let { hospitalId } = params.data;
      if (!hospitalId) {
        return;
      }
      let paramsData = {
        startTime: params.selectedRange[0],
        endTime: params.selectedRange[1],
        hospitalId,
      }
      api
        .getHospitalExecutionRate(paramsData)
        .then(({ data }) => {
          if (data.length == 0) {
            this.implementationRate.noData = true;
          }
          data.forEach(item => {
            this.implementationRate.xAxisData.push(moment(item.day).format('YYYY-MM-DD'));
            if (item.rate == undefined || item.rate == null || item.rate == '') {
              this.implementationRate.series[0].yAxisData.push(null)
            } else {
              this.implementationRate.series[0].yAxisData.push((Number(item.rate) * 100).toFixed(2));
            }
          });
        });
    },
    callback(key) {
      this.clearData();
      this.currentTab = key;
    },
    clearData() {
      this.implementationRate.xAxisData = [];
      this.implementationRate.series = [{
        name: "执行率",
        color: '#6093FF',
        yAxisData: [],
      }
      ]

      this.adoptionRate.xAxisData = [];
      this.adoptionRate.series[0].yAxisData = [];
      this.adoptionRate.series[1].yAxisData = [];
    },
    // 下载数据
    derive() {
      if (this.rankList.length > 0) {
        excelUtil.exportExcel(this.columns, this.rankList, "医嘱执行率排名.xlsx");
      } else {
        this.$message.warning("无数据导出！");
      }
    },
  },
  watch: {

  },
};
</script>
<style scoped lang="less">
.table-plate {
  height: calc(100vh - 280px);
  background: #fff;
  border-radius: 30px;
  padding: 25px 30px 30px;
}

.filter-items {
  background: #fff;
  border-radius: 0 0 30px 30px;
  padding: 30px 30px 15px;
  margin-bottom: 15px;

  .ant-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .ant-col {
      margin-right: 20px;
      margin-bottom: 15px;

      .ant-input {
        width: 68px;
      }

      .ant-btn-primary {
        background: #2468f2;
      }
    }
  }
}

/deep/.ant-tabs-bar {
  background: #fff;
  border-radius: 30px 30px 0 0;
  padding: 28px 30px 0;
  margin-bottom: 0;
}

/deep/.ant-tabs-nav .ant-tabs-tab {
  padding: 0 0 18px 0;
  margin-right: 9vw;
}

.chart-contanier {
  height: 100%;
  overflow: auto;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 330px) !important;
}
</style>